.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.rdp-caption {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 2.5rem !important;
}

.rdp-caption_dropdowns {
  display: flex !important;
  gap: 8px !important;
  position: static !important;
}

/* Hide Month/Year labels */
.rdp-vhidden {
  display: none !important;
}

/* Style dropdowns */
.rdp-dropdown {
  /* appearance: none; */
  padding: 0 4px;
  outline: none;
  border: none;
  background: transparent;
  font-weight: 500;
}

/* Position navigation buttons */
.rdp-nav {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: space-between;
  padding: 0 4px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.textarea-error:focus {
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}
.textarea-error:focus-visible {
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2) !important;
}

.card {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 1.05rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.05rem;
  color: #0f172a;
  margin-bottom: 1.05rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
