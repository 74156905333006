@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 212 81% 37%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 212 81% 37%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 212 81% 37%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 212 81% 37%;

    --radius: 0.5rem;

    --lightblue: 210 67% 82%;
    --lightblue-foreground: 0 0% 98%;

    --ahedgeblue: 210 79% 37%;

    --ahedgeblueTextHover: 210 85% 25%;

    --select-placeholder: 215 20.2% 75%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;

    --lightblue: 210 67% 82%;
    --lightblue-foreground: 0 0% 98%;

    --select-placeholder: 215 20.2% 45%;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    overflow: hidden;
  }

  .btn-ahedge-outline {
    border-color: hsl(var(--ahedgeblue)) !important;
    color: hsl(var(--ahedgeblue));
  }
  .formTitle {
    /* color: hsl(var(--ahedgeblue)); */
  }
  .pageTitle {
    color: hsl(var(--ahedgeblue));
  }
  .headerBg {
    background-color: hsl(var(--ahedgeblue));
  }
  .ahBlueText {
    color: hsl(var(--ahedgeblue));
  }
  .ahBlueTextHover {
    color: hsl(var(--ahedgeblueTextHover));
  }
  .ahDarkBlueText {
    color: #003c78;
  }
  .lightBlueBg {
    background-color: #f5faff;
  }
  .ahBlueBg {
    background-color: hsl(var(--ahedgeblue));
  }
  .bgBlueHighlight {
    background-color: #e2f0fe;
  }

  [data-placeholder] {
    color: theme('colors.custom-placeholder');
  }

  .select-trigger [data-placeholder] {
    color: theme('colors.custom-placeholder');
  }

  input::placeholder,
  textarea::placeholder {
    color: theme('colors.custom-placeholder') !important;
  }
  [data-field] {
    scroll-margin-top: 100px;
    scroll-behavior: smooth;
  }
}

@layer components {
  .select-trigger [data-placeholder] {
    @apply text-muted-foreground;
    color: hsl(var(--select-placeholder));
  }
}
